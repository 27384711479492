import React from 'react';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

const MM_SERVER_ALLAN = "https://github.com/mattermost/mattermost-server/pulls?utf8=%E2%9C%93&q=is%3Apr+is%3Aclosed+AGMETEOR"
const MM_WEBAPP_ALLAN = "https://github.com/mattermost/mattermost-webapp/pulls?utf8=%E2%9C%93&q=is%3Apr+is%3Aclosed+AGMETEOR"
const MM_JIRA_PLUGIN_ALLAN = "https://github.com/mattermost/mattermost-plugin-jira/pulls?utf8=%E2%9C%93&q=is%3Apr+is%3Aclosed+AGMETEOR"
 

class Development extends React.Component {
    render() {
        return (
            <Timeline lineColor={'#ddd'}>
  <TimelineItem
    key="001"
    dateText="June 2018"
  >
    <p>Switched career from Petroleum Geosciences, applied and joined a tech bootcamp with Andela.</p>
  </TimelineItem>
  <TimelineItem
    key="002"
    dateText="September 2019"
    dateInnerStyle={{ background: '#61b8ff' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <p>
        Became a contributor to the Mattermost open source project, <a href={MM_SERVER_ALLAN}>Server: Go</a>,
        {' '}
        <a href={MM_WEBAPP_ALLAN}>webapp: React</a>, 
        {' '}
        <a href={MM_JIRA_PLUGIN_ALLAN}>Jira plugin: Cloud</a>, 
        {' '}
        I have been making contributions to their webapp
        written in React and Redux. I also contributed to their backend server written in Go and packaged using 
        Docker and docker-compose
    </p>
  </TimelineItem>
  <TimelineItem
    key="003"
    dateText="October 2019"
  >
    <p>Became a contributor to the <a href="https://github.com/deislabs/porter/pulls?utf8=%E2%9C%93&q=is%3Apr+is%3Aclosed+AGMETEOR">Porter open source project</a> by Deislabs.</p>
  </TimelineItem>
  <TimelineItem
    key="004"
    dateText="2020"
    dateInnerStyle={{ background: '#61b8ff' }}
  >
    <p>Selected for the Udacity Nano degree challenge course in Cloud DevOps.</p>
  </TimelineItem>
</Timeline>
        )
    }
}

export default Development;