import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'
import Development from '../components/Development';

import thumb01 from '../assets/images/fulls/01.jpg'
import thumb02 from '../assets/images/fulls/02.jpg'
import thumb03 from '../assets/images/fulls/03.jpg'
import thumb04 from '../assets/images/fulls/04.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'

const DEFAULT_IMAGES = [
    { id: '1', source: full01, thumbnail: thumb01, caption: 'Photo 1', description: 'OSFC card'},
    { id: '2', source: full02, thumbnail: thumb02, caption: 'Photo 2', description: 'A picture with Ron Minnich.'},
    { id: '3', source: full03, thumbnail: thumb03, caption: 'Photo 3', description: 'Breakfast at the conference.'},
    { id: '4', source: full04, thumbnail: thumb04, caption: 'Photo 4', description: 'Riding a Google bike.'},
];

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Portfolio | Allan Guwatudde"
        const siteDescription = "Allan Guwatudde's developer portfolio"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">
                    <section id="one">
                        <header className="major">
                            <h2>My journey to becoming a professional software developer.</h2>
                        </header>
                        <p>Here's a small timeline for my software development journey.</p>
                        <Development />
                    </section>
                    <section>
                    <h2>Projects</h2>
                    <div>
                        <h5>My talk on OSS</h5>
                        <p>I was selected to give a talk to fellow Udacity Nano degree students on my OSS experience</p>
                        <p>Link: <a href="https://www.youtube.com/watch?v=K7U_99DcO7E&t=6s">Impact of good open source on a self-taught developer - Udacity Student Conference</a></p>
                    </div>
                    <div>
                        <h5>Bamboohr plugin for Mattermost</h5>
                        <p>This was started after participation in the Mattermost hackerthon</p>
                        <p>Link: <a href="https://github.com/AGMETEOR/mattermost-plugin-bamboohr">Mattermost BambooHR plugin</a></p>
                    </div>
                    <div>
                        <h5>Digital Ocean plugin for Mattermost</h5>
                        <p>Built this plugin as part of our team "AGMETEOR" with Phillip during the Mattermost bot hackerthon 2020</p>
                        <p>Link: <a href="https://github.com/phillipahereza/mattermost-plugin-digitalocean">Mattermost Digital Ocean plugin</a></p>
                    </div>
                    </section>
                    <section id="two">
                        <h2>Some highlights from my 2019 software development journey</h2>
                        <p>In 2019 I was lucky to get a travel scholarship for diversity from the <a href="https://osfc.io/"> Open Source Firmware Conference (OSFC). </a>
                        The conference was organized at Google's office in Sunnyvale and Facebook's office in Palo Alto.
                        </p>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />
                    </section>

                    <section id="three">
                        <h2>Get In Touch</h2>
                        <p>I am open for video call or if we're in the same location we can definitely meet up and discuss tech.</p>
                        <div className="row">
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        Ntinda,<br />
                                        Kampala<br />
                                        Uganda
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        +256778665847
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="#">allanguwatudde@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex